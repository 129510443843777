$(function () {
  const $htmlBodyElements = $('html, body');

  $('.js-waypoint').on('click', function(event) {
    event.preventDefault();

    const $element = $($(this).attr('href'));

    if (!$element.length) {
      return;
    }

    $htmlBodyElements.animate(
      {
        scrollTop: $element.offset().top,
      },
      1500
    );
  });
});
