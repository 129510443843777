// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require('jquery')

import "../stylesheets/style.scss"
const images = require.context('../images', true)

/*
 * Polyfills
 */
import '../src/polyfills/object-fit';

/*
 * Modules
 */

import '../src/modules/init-waypoints';
import '../src/modules/init-accordion';
import '../src/modules/field-events-handle';
