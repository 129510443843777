$(function() {
  $('.js-accordion-head').on('click', function(event) {
    event.preventDefault();

    const $this = $(this);
    const currentIndex = $this.closest('.js-accordion-section').index() + 1;

    $this
    .next('.js-accordion-body')
    .slideToggle()
    .parent()
    .toggleClass('is-active')
    .siblings()
    .removeClass('is-active')
    .find('.js-accordion-body')
    .slideUp();

    $this
    .closest('.js-holder')
    .find(`.js-images-holder > img:nth-child(${currentIndex})`)
    .addClass('is-active')
    .siblings()
    .removeClass('is-active');
  });
})
